import React, { useContext, useState } from 'react'
import styles from './Navbar.module.scss'
// import Button from '../../Common/Button/Button';
import Button from '../Button/Button'
import Link from 'next/link'
import AppContext from '../../../context/AppContext'
import Modal from '../Modal/Modal'
import ContactForm from '../Contact/Contact'
const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false)
  const { contactForm, setContactForm }: any = useContext(AppContext)
  return (
    <nav className={styles['navbar-wrapper']}>
      <div className={styles.navbar}>
        {/* <Link href={'/'}> */}
        <div className={styles.logoContainer}>
          <Link href={'/'} style={{ display: 'flex', alignItems: 'center' }}>
            <img src='logo.svg' alt='logo' style={{ width: 25, height: 25 }} />
            <p className={styles.logoText}>socommerz</p>
          </Link>
        </div>
        {/* </Link> */}

        <div>
          <ul className={styles.list}>
            <li className={styles.listItem}>
              <Link href={'about-us'}>About</Link>
            </li>
            <li className={styles.listItem}>
              <Link href={'product-stack'}>Product</Link>
            </li>

            <li className={styles.listItem}>
              Solutions<span>&#8964;</span>
              {/* <Link href={'business'}>Solutions</Link> */}
              <ul className={styles['dropdown']}>
                <li className={styles['dropdown-item']}>
                  <Link href='/b2b-stack'>B2B Stack</Link>
                </li>
                <li className={styles['dropdown-item']}>
                  <Link href='/b2c-d2c-stack'>B2C & D2C Stack</Link>
                </li>
                <li className={styles['dropdown-item']}>
                  <Link href='/social-commerce'>Social Commerce Stack</Link>
                </li>
              </ul>
            </li>
            <li className={styles.listItem}>
              <Link href={'/generative-ai'}>Generative AI</Link>
            </li>

            {/* <li className={styles.listItem}>
              <Link href={'/generative-ai'}>Generative AI</Link>
              <div className={styles["mega-drop"]}>
                <div className={styles["mega-grid"]}>
                 
                   <div className={styles["mega-child-first"]}>
                      <h5>BY STAGE</h5>
                      <ul>
                        <li><a href="#">Start-ups </a></li>
                        <li> <a href="#">Enterprises  </a></li>
                      </ul>
                  
                      <a href="#">Enterprises  </a>
                   </div>
                   <div className={styles["mega-child-second"]}>
                      <h5>BY USE CASE</h5>
                      <ul>
                        <li><a href="#">Start-ups </a></li>
                        <li> <a href="#">Enterprises  </a></li>
                      </ul>
                   </div>
                   <div className={styles["mega-child-third"]}>
                      <h5>INTEGRATIONS & CUSTOM SOLUTIONS</h5>
                      <ul>
                        <li><a href="#">Start-ups </a></li>
                        <li> <a href="#">Enterprises  </a></li>
                      </ul>
                   </div>

                </div>
              </div>
            </li> */}

            {/* <li className={styles.listItem}>
              <Link href={'technology'}>Technology</Link>
            </li> */}
          </ul>
        </div>
        <div className={styles.buttonDiv}>
          <Link
            target='blank'
            href='https://docs.google.com/forms/d/e/1FAIpQLScUKXrtoPaq2W2ag_cyszvEQkfs8ou4RV-BXd93rJl9E48y0g/viewform?usp=sf_link'
          >
            <Button text='We Are Hiring' classname='button-wrapper' />
          </Link>
        </div>
        <div onClick={() => setShowMenu(showMenu === true ? false : true)}>
          {/* <p className={styles.more}>=</p> */}
          {/* <p style={{color:'white', position:'absolute', top:20, right:30}}>=</p> */}
          <img src='nav.svg' alt='more' className={styles['more']} />
        </div>
      </div>

      <nav role='navigation' className={styles['mobile-navigation']}>
        <div
          id={`${styles['menuToggle']}`}
          onClick={() => setShowMenu(!showMenu)}
        >
          {/* <form> */}
          <label htmlFor='hamburger'>Menu</label>
          <input
            type='checkbox'
            checked={showMenu}
            onChange={(e) => {}}
            id='hamburger'
            name='hamburger'
            aria-labelledby='hamburger'
          />
          <span></span>
          <span></span>
          <span></span>
          {/* </form> */}
          <ul id={styles['menu']}>
            <Link href='/about-us'>
              <li>
                <button>About Us</button>
              </li>
            </Link>
            <Link href='/product-stack'>
              <li>
                <button>Product Stack</button>
              </li>
            </Link>
            <Link href='/b2b-stack'>
              <li>
                <button>B2B Stack</button>
              </li>
            </Link>
            <Link href='/b2c-d2c-stack'>
              <li>
                <button>B2C & D2C Stack</button>
              </li>
            </Link>
            <Link href='/social-commerce'>
              <li>
                <button>Social Commerce Stack</button>
              </li>
            </Link>
            <Link href='/generative-ai'>
              <li>
                <button>Generative AI</button>
              </li>
            </Link>
            <Link
              target='blank'
              href='https://docs.google.com/forms/d/e/1FAIpQLScUKXrtoPaq2W2ag_cyszvEQkfs8ou4RV-BXd93rJl9E48y0g/viewform?usp=sf_link'
            >
              <li>
                <button>We Are Hiring</button>
              </li>
            </Link>
          </ul>
        </div>

        <Link href={'/'} className={styles['mobile-logo']}>
          <img src='logo.svg' alt='logo' style={{ width: 20, height: 20 }} />
          <span>SOCOMMERZ</span>
        </Link>
      </nav>

      {contactForm && (
        <Modal>
          <ContactForm />
        </Modal>
      )}
    </nav>
  )
}

export default Navbar
